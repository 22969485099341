//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import { VueRecaptcha } from 'vue-recaptcha';

//import { loadScript } from "vue-plugin-load-script";
export default {
    name: 'ContactUs',
    components: {
        //VueRecaptcha
    },

    data: function () {
        let self = this;
        return {
            dictionary: {
                en: {
                    messages: {
                        required: function required(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('required');
                        },
                        email: function email() {
                            return 'Invalid Email Format';
                        },
                        max: function max(fieldName, numbers) {
                            return self.$t(fieldName) + self.$t('max') + " " + numbers + " " + self.$t('numbers');
                        }, min: function min(fieldName, numbers) {

                            return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                        },
                        alpha: function alpha_spaces(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('alphaSpaces');
                        },
                        //between: function between(fieldName, n1, n2) {
                        //  return self.$t(fieldName) + ' ' + n1 + ' ' + n2;
                        //},
                        confirmed: function confirmed(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                        },
                        regex: function regex(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                        },

                        between: function between(fieldName, n) {
                            return self.$t(fieldName) + ' ' + ' Should be Between ' + n[0] + ' and ' + n[1];
                        },
                        numeric: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('should be Number');
                        },
                        verify_password: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                        },
                        mobileReg: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('Number');
                        }
                    }
                },
                ar: {
                    messages: {
                        required: function required(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('required');
                        },
                        email: function email() {
                            return 'تنسيق بريد إلكتروني غير صالح';
                        },
                        max: function max(fieldName, numbers) {
                            return self.$t(fieldName) + self.$t('max') + numbers + self.$t('numbers');
                        }, min: function min(fieldName, numbers) {

                            return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                        },
                        alpha: function alpha_spaces(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('shouldBeCharOnly');
                        },
                        //between: function between(fieldName, n) {
                        //     return self.$t(fieldName) + self.$t('min') + n[0] + self.$t('max') + n[1];
                        //},
                        confirmed: function confirmed(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                        },
                        regex: function regex(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                        },
                        between: function between(fieldName, n) {
                            return self.$t(fieldName) + ' ' + 'يجب ان يكون بين ' + n[0] + 'و' + n[1];
                        },
                        numeric: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('should be Number');
                        },
                        verify_password: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                        },
                        mobileReg: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('Number');
                        }
                    }
                }

            },
            model: {},
            showValidMessage: false,
            CityList: [],
            SectionList: [],
            contactInfo: [],
            successmsg: self.$t('savedSucess'),
            warnmesg: self.$t('warnmsg'),
            isActive: true,
            //rcapt_sig_key: "HERE LIVE GOOGLE API KEY",
            //live_google_recpachid: 0
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        onSubmit: function () {
            alert("Any thing");
            console.log("Any thing");
        },
        notify: function (message, fn = "error") {
            let self = this;
            self.$snotify.clear();
            if (fn == "success") {
                self.$snotify.success(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else if (fn == "warning") {
                self.$snotify.warning(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else {
                self.$snotify.html(message, {
                    type: "error",
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
        },
        onlyNumber($event) {
            
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        validateModel: function () {
            var self = this;
            var { Name, Email, PhoneNumber, CityId, SectionId, Subject, Message } = self.model
            return Name && Name.length && Email && Email.length && PhoneNumber && PhoneNumber.length
                && CityId && SectionId && Subject && Subject.length && Message && Message.length;
        },
        ClearModel: function () {
            var self = this;
            self.model = {};
            self.showValidMessage = false;
        },
        Submit: function () {
            var self = this;
            //self.showValidMessage = true;
            //if (!self.validateModel())
            //    return;
            self.axios
                .post(process.env.VUE_APP_APIEndPoint + "ContactMessage/Add", self.model)
                .then(function (res) {
                    self.notify(self.$t('savedSucess'), "success");
                    self.ClearModel();
                    self.scrollToTop();

                });
        },
        loadCity: function () {
            var self = this;
            self.axios
                .get(process.env.VUE_APP_APIEndPoint + "City/PublicGetAll")
                .then((response) => {
                    self.CityList = response.data.Data;
                });
        },
        loadSection: function () {
            var self = this;
            self.axios
                .get(process.env.VUE_APP_APIEndPoint + "Section/PublicGetAll")
                .then((response) => {
                    self.SectionList = response.data.Data;
                });
        },
        loadContactInfo: function () {
            var self = this;
            self.axios
                .get(process.env.VUE_APP_APIEndPoint + "ContactInfo/PublicGetAll")
                .then((response) => {
                    self.contactInfo = response.data.Data;
                });
        },


        save: function () {
            let self = this;
            self.showValidMessage = true;
            self.$validator.validate().then(function (result) {
                if (!result) { ////not valid
                    let detail = $('.form-error').first()[0];
                    if (detail) {
                        detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                        self.notify(self.$t('warnmsg'), "warning");

                    }
                    return;
                }
                $('.form-error').removeClass("form-error");
                self.Submit();


            });
        },

    },
    created: function () {
        var self = this;
        self.loadCity();
        self.loadSection();
        self.loadContactInfo();

        //loadScript("../scripts/reCaptcha.js").then(() => {
        //    debugger
        //    console.log("SUCESS")
        //}).catch(() => {
        //    console.log("FAILED")
        //})

    },
    mounted() {
        let self = this;
        this.$validator.localize(`${self.$i18n.locale}`, self.$i18n.locale == 'en' ? this.dictionary.en : this.dictionary.ar);

    }
};
